import React, { useEffect } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signUp as signUpAction, SignUpAction } from '@triare/auth-redux/dist/saga/auth/signUp';
import { Action } from '@triare/auth-redux';
import { useNavigate } from 'react-router-dom';
import Form from '../Form';
import { validationSignUpEmailAndPassword, createRulesForAntd } from '../../../../../utils/validations';
import { ContentProps } from '../index';
import { useAuth } from '../../../../../store/auth';
import Footer from '../Footer';
import Separator from '../../../../Common/Form/Separator';
import ButtonGoogle from '../Form/ButtonGoogle';
import ButtonApple from '../Form/ButtonApple';
import ButtonFacebook from '../Form/ButtonFacebook';

const validationRules = createRulesForAntd(validationSignUpEmailAndPassword);

interface EmailAndPasswordProps extends ContentProps {
  signUp: (payload: SignUpAction) => Action;
}

function EmailAndPassword({ signUp }: EmailAndPasswordProps): React.ReactNode {
  const { signUp: { loading, response, error } } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (response && error === null && !loading && response.secretKey) {
      navigate('./information');
    }
  }, [response, loading, error]);

  return (
    <>
      <Form<SignUpAction>
        validationRules={validationRules}
        signUp={({ confirmPassword, ...data }) => signUp(data)}
        name="signUpEmail"
      >
        <AntdForm.Item
          name="email"
          label={`${t('email')} *`}
          rules={[validationRules]}
        >
          <Input
            size="large"
            placeholder={t('email') || 'Email'}
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')} *`}
          rules={[validationRules]}
        >
          <Input.Password
            placeholder={t('password') || 'Password'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="confirmPassword"
          label={`${t('confirmPassword')} *`}
          dependencies={['password']}
          rules={[
            validationRules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Passwords that you entered do not match!');
              },
            })]}
        >
          <Input.Password
            placeholder={t('confirmPassword') || 'Confirm password'}
            size="large"
          />
        </AntdForm.Item>
      </Form>

      {/* <Separator /> */}

      {/* <AntdForm.Item> */}
      {/*  <ButtonGoogle /> */}
      {/* </AntdForm.Item> */}

      {/* <AntdForm.Item> */}
      {/*  <ButtonApple /> */}
      {/* </AntdForm.Item> */}

      {/* <AntdForm.Item> */}
      {/*  <ButtonFacebook /> */}
      {/* </AntdForm.Item> */}

      <Footer />
    </>
  );
}

export default connect(null, {
  signUp: signUpAction,
})(EmailAndPassword);
