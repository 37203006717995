import React, { CSSProperties } from 'react';
import { Button, Form } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { AnyObject } from '@triare/auth-redux';

interface SubmitProps extends ButtonProps {
  children: React.ReactNode | string;
  requiredFields?: '*' | string[];
  itemStyle?: CSSProperties;
  itemClassName?: string;
  validateSubmit?: (fieldsValue: AnyObject) => boolean
}

function Submit({
  children, itemClassName, validateSubmit, itemStyle, requiredFields, ...props
}: SubmitProps) {
  return (
    <Form.Item
      shouldUpdate
      className={itemClassName}
      style={itemStyle}
    >
      {({ getFieldsValue, getFieldsError }) => {
        const fieldsValue = getFieldsValue();
        const fieldList = Object.keys(fieldsValue);
        const fieldsError = getFieldsError();
        let required: boolean;

        if (validateSubmit) {
          required = fieldList.length === 0
            || validateSubmit(fieldsValue)
            || fieldsError.some((item) => item.errors.length);
        } else {
          required = fieldList.length === 0
            || fieldsError.some((item) => item.errors.length) || !!(requiredFields && requiredFields !== '*'
            ? requiredFields.find((key) => !fieldsValue[key])
            : Object.keys(fieldsValue).some((key) => fieldsValue[key] === '' || fieldsValue[key] === undefined)
          );
        }

        return (
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            disabled={required}
            {...props}
          >
            {children}
          </Button>
        );
      }}
    </Form.Item>
  );
}

Submit.defaultProps = {
  requiredFields: '*',
  validateSubmit: undefined,
  itemClassName: undefined,
  itemStyle: undefined,
};

export default Submit;
