import React from 'react';
import { config } from '@triare/auth-redux';
import EmailAndPassword from '../../../components/Pages/Auth/SignUp/EmailAndPassword';
import Simple from '../../../components/Layout/Simple';
import SignUpContent from '../../../components/Pages/Auth/SignUp';

const { displayName } = config;

function SignUp(): React.ReactNode {
  document.title = `${displayName}: Sign up`;

  return (
    <Simple>
      <SignUpContent content={EmailAndPassword} />
    </Simple>
  );
}

export default SignUp;
