import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initialState } from './store/common';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.REACT_APP_ENV === 'local',
    fallbackLng: initialState.lang,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        menu: {},

        translation: {
          email: 'Email',
          password: 'Password',
          name: 'Name',
          userName: 'User name',
          firstName: 'First name',
          lastName: 'Last name',
          surname: 'Surname',
          phone: 'Phone',
          contactPhone: 'Phone Number',
          repeatPassword: 'Repeat password',
          confirmPassword: 'Confirm password',

          signIn: {
            google: 'Sign in with google',
            facebook: 'Sign in with facebook',
            apple: 'Sign in with apple',
          },

          signUp: {
            google: 'Sign up with google',
            facebook: 'Sign up with facebook',
            apple: 'Sign up with apple',
          },
        },
      },
    },
  });

export default i18n;
