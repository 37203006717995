import React, { useEffect } from 'react';
import Content from '../../Common/Content';
import { useLayoutContext } from '../../Layout/context';

import styles from './index.module.scss';

export default function Dashboard(): React.ReactNode {
  const { setBreadcrumbs } = useLayoutContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        title: 'Dashboard',
      },
    ]);
  }, []);

  return (
    <>
      <h2>Dashboard</h2>

      <Content>
        Dashboard page
      </Content>
    </>
  );
}
