/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router-dom';
import { useMemo } from 'react';
import { select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../index';
import { Lang, listLang } from '../../AntdConfigProvider';

export const moduleName = 'common';

export type Redirect = { to: To, options?: NavigateOptions } | null

export interface State {
  lang: Lang;
  redirect: Redirect
}

const localLang = window.localStorage.getItem('lang') || '';

export const initialState: State = {
  lang: (Object.keys(listLang).includes(localLang) ? localLang : Object.keys(listLang)[0]) as Lang,
  redirect: null,
};

export const common = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setLang: (state, { payload }) => {
      state.lang = payload;
    },
    triggerRedirect: (state, { payload }) => {
      state.redirect = payload;
    },
  },
});

export const { actions } = common;

// action creator
export function triggerRedirect(to: To, options?: NavigateOptions) {
  return {
    type: actions.triggerRedirect.toString(),
    payload: {
      to,
      options,
    },
  };
}

export function useRedirectSelector(): Redirect {
  return useSelector((state: RootState) => state[moduleName].redirect);
}

export function useLang(): { setLang: (lang: string) => void; lang: Lang } {
  const lang = useSelector((state: RootState) => state[moduleName].lang);
  const dispatch = useDispatch();

  return useMemo(() => ({
    setLang: (payload: string) => dispatch({
      type: actions.setLang.toString(),
      payload,
    }),
    lang,
  }), [lang]);
}

function* saveLangToLocalStorage() {
  const { lang } = yield select((state) => state[moduleName]);

  window.localStorage.setItem('lang', lang);
}

export function* saga(store: EnhancedStore) {
  yield takeLatest(actions.setLang.toString(), saveLangToLocalStorage);
}
