import { HomeOutlined } from '@ant-design/icons';
import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLayoutContext } from '../context';

export default function Breadcrumb() {
  const { breadcrumbs } = useLayoutContext();
  const navigate = useNavigate();

  return (
    <AntdBreadcrumb
      items={[
        {
          href: './',
          onClick: (e) => {
            e.preventDefault();
            navigate('/');
          },
          title: <HomeOutlined />,
        },
        ...breadcrumbs,
      ]}
    />
  );
}
