import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { AlertProps } from 'antd/es/alert';
import { RootState } from '../../../../../../../store';
import { /* actions, */ moduleName } from '../../../../../../../store/auth';
import { getMessageInError } from '../../../../../../../hooks/fetch';

export interface AlertErrorProps extends AlertProps {
  disable?: boolean;
  error: Error | null;
}

function AlertError({
  error, disable, ...props
}: AlertErrorProps): React.ReactNode {
  const dispatch = useDispatch();

  if (disable === false && error) {
    return (
      <Alert
        type="error"
        message={getMessageInError(error)}
        closable
        // onClose={() => dispatch({
        //   type: actions.signUpErrorReset.toString(),
        // })}
        {...props}
      />
    );
  }

  return null;
}

AlertError.defaultProps = {
  disable: false,
};

export default connect((state: RootState) => ({
  error: state[moduleName].signUp.error,
}))(AlertError);
