import React, { createContext, useMemo, useState } from 'react';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useNavigate } from 'react-router-dom';

export interface LayoutContextProps {
  breadcrumbs: ItemType[]
  setBreadcrumbs: (items: ItemType[]) => void
}

const defaultValue: LayoutContextProps = {
  breadcrumbs: [],
  setBreadcrumbs: () => {
    // default
  },
};

export const LayoutContext = createContext<LayoutContextProps>(defaultValue);

export interface LayoutProviderProps {
  children: React.ReactNode;
}

function LayoutProvider({ children }: LayoutProviderProps) {
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState<ItemType[]>([]);

  const value = useMemo(() => ({
    breadcrumbs,
    setBreadcrumbs: (list: ItemType[]) => {
      setBreadcrumbs(list.map((data: ItemType) => ({
        onClick: data.href ? (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          if (data.href) {
            navigate(data.href);
          }
        } : undefined,
        ...data,
      })));
    },
  }), [breadcrumbs]);

  return (
    <LayoutContext.Provider
      value={value}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutProvider;

export const useLayoutContext = ():
  LayoutContextProps => React.useContext(LayoutContext);
