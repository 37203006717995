import React, { useEffect, useState } from 'react';
import {
  Layout as AntdLayout, Button, theme, Dropdown, Avatar,
} from 'antd';
import {
  HomeOutlined, KeyOutlined, LeftOutlined, LogoutOutlined, RightOutlined, UserOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useNavigate } from 'react-router-dom';
import { GB, DE } from 'country-flag-icons/react/3x2';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import ReduxRedirect from '../Common/ReduxRedirect';
import { LogoLarge, LogoSmall } from '../Common/Icon';
import LayoutProvider from './context';
import { useLang } from '../../store/common';
import { Lang } from '../../AntdConfigProvider';
import store from '../../store';
import { useAuth } from '../../store/auth';

import styles from './index.module.scss';
import Breadcrumb from './Breadcrumb';

const { Header, Sider, Content } = AntdLayout;

interface LayoutProps {
  LeftMenu?: React.FC;
  leftMenu?: React.ReactNode;
  transparent?: boolean;
  disableLeftMenu?: boolean;
  children: React.ReactNode;
  colapsedByDefault?: boolean;
  minWidth?: boolean;
}

let defaultStateCollapsed = false;

const itemsLang: { key: Lang, icon: React.ReactNode, label: string }[] = [
  {
    key: 'en',
    icon: <GB style={{ width: 16, height: 12 }} />,
    label: 'English',
  },
  {
    key: 'de',
    icon: <DE style={{ width: 16, height: 12 }} />,
    label: 'German',
  },
];

export default function Layout({
  children,
  disableLeftMenu = false,
  colapsedByDefault = false,
  transparent = undefined,
  LeftMenu = undefined,
  leftMenu = undefined,
  minWidth = undefined,
}: LayoutProps): React.ReactNode {
  const { user } = useAuth();
  const { lang, setLang } = useLang();
  const navigate = useNavigate();
  const [width, setWidth] = useState(208);
  const [collapsed, setCollapsed] = useState(defaultStateCollapsed);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  useEffect(() => {
    const resize = () => {
      setWidth(Math.min(Math.max(208, Math.round(window.innerWidth * 0.15)), 248));
    };

    window.addEventListener('resize', resize, false);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const disableMenu = disableLeftMenu || (!LeftMenu && !leftMenu);

  useEffect(() => {
    defaultStateCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if ((isTablet && !disableMenu) || colapsedByDefault) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTablet, disableMenu]);

  const itemsUser = [
    {
      key: '/edit',
      label: (
        <div className={styles.selectLang}>
          <UserOutlined />
          {' '}
          Edit profile
        </div>
      ),
      onClick: () => navigate('/edit'),
    },
    {
      key: '/change-password',
      label: (
        <div className={styles.selectLang}>
          <KeyOutlined />
          {' '}
          Change password
        </div>
      ),
      onClick: () => navigate('/change-password'),
    },
    {
      key: '/sign-out',
      label: (
        <div className={styles.selectLang}>
          <LogoutOutlined />
          {' '}
          Sign out
        </div>
      ),
      onClick: () => store.dispatch(signOut()),
    },
  ];

  return (
    <LayoutProvider>
      <ReduxRedirect />
      <AntdLayout hasSider className={styles.layout}>
        {disableMenu ? null : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={clsx(styles.slider, { [styles.absolute]: isTablet && !disableMenu })}
            width={width}
            collapsedWidth={48}
          >
            <NavLink to="/" className={clsx(styles.logo, { [styles.collapsed]: collapsed })}>
              {collapsed ? <LogoSmall className={styles.logoSvg} /> : <LogoLarge className={styles.logoSvg} />}
            </NavLink>

            <div className={clsx(styles.menu, { [styles.collapsed]: collapsed })}>
              {LeftMenu ? <LeftMenu /> : leftMenu}
            </div>

            <Button
              type="text"
              icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              className={clsx(styles.btn, { [styles.collapsed]: collapsed })}
            />
          </Sider>
        )}
        <AntdLayout className={clsx(styles.wrapper, { [styles.tablet]: isTablet && !disableMenu })}>
          <Header
            style={{ background: colorBgContainer }}
            className={styles.header}
          >
            <div>
              <Breadcrumb />
            </div>
            <div className={styles.gridGap}>
              <Dropdown
                menu={{
                  items: itemsLang,
                  activeKey: lang,
                  onClick: ({ key }) => setLang(key),
                }}
                placement="bottomRight"
              >
                <Button type="text" icon={itemsLang.find(({ key }) => key === lang)?.icon} />
              </Dropdown>

              {user ? (
                <Dropdown
                  menu={{
                    items: itemsUser,
                    activeKey: window.location.pathname,
                  }}
                  placement="bottomRight"
                >
                  <Button type="text" style={{ paddingRight: 8 }}>
                    <Avatar size="small" icon={<UserOutlined />} className={styles.avatar} />
                    {user.email}
                  </Button>
                </Dropdown>
              ) : null}
            </div>
          </Header>
          <div className={clsx(styles.scroll, { [styles.collapsed]: collapsed })}>
            <Content
              className={clsx(
                styles.content,
                { [styles.transparent]: transparent },
                { [styles.minWidth]: isTablet && minWidth },
              )}
              id="layout-content"
            >
              {children}
            </Content>
          </div>
        </AntdLayout>
      </AntdLayout>
    </LayoutProvider>
  );
}
