import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import NavigationsContent from '../../components/Pages/Navigations';
import Menu from '../../components/Layout/Menu';

const { displayName } = config;

export default function Navigations(): React.ReactNode | null {
  document.title = `${displayName}: Navigations page`;

  return (
    <Layout LeftMenu={Menu} transparent>
      <NavigationsContent />
    </Layout>
  );
}
