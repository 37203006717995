import React, { CSSProperties, useEffect } from 'react';
import { Row, FormInstance } from 'antd';
import clsx from 'clsx';
import InputCode from './Input';
import { numberOTP } from '../../../../../contstant';

import styles from './index.module.scss';

interface InputCodeWrapperProps {
  className?: string;
  style?: CSSProperties;
  form: FormInstance;
}

function InputCodeWrapper({ className, form, style }: InputCodeWrapperProps) {
  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();
      const clipboardText = event?.clipboardData?.getData('text/plain');

      if (clipboardText) {
        for (const [i, number] of [...clipboardText].entries()) {
          form.setFieldValue(`number-${i + 1}`, number);
        }
      }
    };

    window.addEventListener('paste', handlePaste, false);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [form]);

  return (
    <div className={clsx(styles.wrapper, className)} style={style}>
      {Array.from({ length: numberOTP }, (_, i) => i + 1).map((id) => (
        <InputCode key={id} id={id} form={form} />
      ))}
    </div>
  );
}

InputCodeWrapper.defaultProps = {
  style: undefined,
  className: undefined,
};

export default InputCodeWrapper;
