/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */

import {
  config,
  State as AuthState,
  setActions,
  saga as authSaga,
  updateConfig,
  getLocalState,
  Action,
  FETCH_ME_AFTER_SUCCESS_AUTHORIZATION,
  createUrl,
  reducers,
} from '@triare/auth-redux';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  spawn, takeLatest, put, select,
} from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
import { OTPResponse } from '@triare/auth-redux/src/saga/auth/OTP';
import { SignInAction } from '@triare/auth-redux/src/saga/auth/signIn';
import { UrlObject } from '@triare/auth-redux/src/config';
import { AnyObject } from '@triare/auth-redux/src/saga/common';
import { User as AuthUser } from '@triare/auth-redux/src/saga/auth/useMe';
import { RootState } from '../index';

export * from '@triare/auth-redux';

export default updateConfig({
  fetchDelay: Number.parseInt(process.env.REACT_APP_FETCH_DELAY || '0', 10),
  api: {
    url: process.env.REACT_APP_API || '',
  },
  signIn: {
    ...config.signIn,
    requestBody: {
      byEmail: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
      byUsername: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
      byPhone: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
      byService: ({ method, remember, ...data }: SignInAction) => JSON.stringify(data),
    },
    fetchMeAfterSuccess: FETCH_ME_AFTER_SUCCESS_AUTHORIZATION,
  },
  OTP: {
    ...config.OTP,
    fetchMeAfterSuccess: false,
  },
  createUrl: (
    url: string | UrlObject,
    addToUrl?: string,
    payload?: PayloadAction,
    data?: AnyObject,
    _query?: AnyObject,
  ) => createUrl(url, addToUrl, payload, data, {
    ..._query,
    // lang: 'en',
  }),
});

export const moduleName = config.auth.name;

export interface User extends AuthUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  settings: {
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  };
}

export interface State extends AuthState {
  user: User | null;
  secretKey: string;
}

export function useAuth(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export const auth = createSlice({
  name: moduleName,
  initialState: getLocalState(),
  reducers: {
    ...reducers,
    signInSuccess: (state, { payload }) => {
      Object.assign(state, payload);

      state.signIn.loading = false;
      state.signIn.response = payload || null;
      state.authorized = !!(payload.access && payload.refresh);

      if (payload?.remember !== undefined) {
        state.remember = payload?.remember;
      }
    },
  },
});

export const { actions } = auth;

setActions(actions);

function* selectAuthState() {
  return (yield select((state: RootState) => state[moduleName])) as State;
}

export function* saga(store: EnhancedStore) {
  yield spawn(authSaga, store);

  yield takeLatest(actions.OTPSuccess.toString(), function* trigger({ payload }: Action<OTPResponse>) {
    yield put({
      type: actions.signInSuccess.toString(),
      payload,
    });
  });
}
