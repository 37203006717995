import React from 'react';

import styles from './index.module.scss';

interface ContentProps {
  children: React.ReactNode
}

export default function Content({ children }: ContentProps): React.ReactNode {
  return (
    <div className={styles.content}>
      {children}
    </div>
  );
}
