import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import EditContent from '../../components/Pages/Edit';
import Menu from '../../components/Layout/Menu';

const { displayName } = config;

export default function Edit(): React.ReactNode | null {
  document.title = `${displayName}: Edit page`;

  return (
    <Layout LeftMenu={Menu} transparent>
      <EditContent />
    </Layout>
  );
}
