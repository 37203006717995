import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import AdministratorsContent from '../../components/Pages/Administrators';
import Menu from '../../components/Layout/Menu';

const { displayName } = config;

export default function Administrators(): React.ReactNode | null {
  document.title = `${displayName}: Administrators page`;

  return (
    <Layout LeftMenu={Menu} transparent>
      <AdministratorsContent />
    </Layout>
  );
}
