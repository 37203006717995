import React, { useMemo } from 'react';
import {
  createSearchParams, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import {
  DashboardOutlined, FileTextOutlined, KeyOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import { useSelector } from 'react-redux';
import { Route } from '../../../routes';
import { RootState } from '../../../store';
import { moduleName, useAuth, User } from '../../../store/auth';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { doesUserHaveAccess } from '../../../routes/PrivateRoute';
import Dashboard from '../../../pages/Dashboard';
import Users from '../../../pages/Users';
import Products from '../../../pages/Products';
import Administrators from '../../../pages/Administrators';
import Navigations from '../../../pages/Navigations';

import styles from './index.module.scss';

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;

  children?: MenuRoute[];
}

export const routes: MenuRoute[] = [
  {
    privateRoute: true,
    name: 'Dashboard',
    icon: <DashboardOutlined />,
    align: 'left',
    route: {
      path: 'dashboard',
      element: <Dashboard />,
    },
  },
  {
    privateRoute: true,
    name: 'Users',
    icon: <UserOutlined />,
    align: 'left',
    route: {
      path: 'users',
      element: <Users />,
    },
  },
  {
    privateRoute: true,
    name: 'Products',
    icon: <FileTextOutlined />,
    align: 'left',
    route: {
      path: 'products',
      element: <Products />,
    },
  },
  {
    privateRoute: true,
    name: 'Administrators',
    icon: <KeyOutlined />,
    align: 'left',
    route: {
      path: 'administrators',
      element: <Administrators />,
    },
  },
  {
    privateRoute: true,
    name: 'Navigation Item',
    icon: <MailOutlined />,
    align: 'left',
    route: {
      path: 'navigations',
      element: <Navigations />,
    },
  },
];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

const createItem = ({
  route, children, ignoreMenu, privateRoute, ...data
}: Route, user: User) => {
  let childrenList;

  if (children && children.length > 0) {
    childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);

    if (childrenList.length > 0) {
      childrenList = childrenList.map(
        ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => ({
          ...child,
          label: child.route ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <NavLink to={`/${child.route.path}${paramsToString(user, child.route.path)}`}>
              {child.name || child.key}
            </NavLink>
          ) : (
            child.name
          ),
          key: child.route ? `/${child.route.path}` : `/${child.key || child.name}`,
        }),
      );
    }
  }

  const name = typeof data.name === 'function' ? data.name(user) : data.name;

  return {
    ...data,
    children: childrenList && childrenList.length > 0 ? childrenList : undefined,
    label: route ? (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <NavLink to={`/${route.path}${paramsToString(user, route.params)}`}>{name}</NavLink>
    ) : (
      name
    ),
    key: route ? `/${route.path}` : `/${data.key || name}`,
  };
};

export const menuLeft = routes.filter(({ ignoreMenu }) => ignoreMenu !== true).filter(({ align }) => align === 'left');

export const menuRight = routes
  .filter(({ ignoreMenu }) => ignoreMenu !== true)
  .filter(({ align }) => align === 'right');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filter(list: any[], user: User) {
  const newList = list.map((item) => ({ ...item, children: item.children ? [...item.children] : undefined }));

  return newList.filter((item) => {
    if (item && item.children && item.children.length > 0) {
      // eslint-disable-next-line no-param-reassign
      item.children = filter(item.children, user);
    }

    return item?.roles ? doesUserHaveAccess(item?.roles, user) : true;
  });
}

export default function Menu(): React.ReactNode {
  const { user } = useSelector((state: RootState) => ({
    user: state[moduleName].user,
  }));
  const { contextHolder } = useSimpleModal();
  const { pathname } = useLocation();
  const menuLeftFiltered = useMemo(() => filter(
    menuLeft.map((data) => createItem(data, user)),
    user,
  ).map((item) => ({
    ...item,
    name: typeof item.name === 'function' ? item.name(user) : item.name,
    icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
  })).map(({ children, ...item }) => item), [user]);

  const activeMenuItem = pathname === '/' ? menuLeftFiltered[0].key : `/${pathname.split('/')[1]}`;

  return (
    <div className={styles.menu}>
      <AntdMenu
        theme="dark"
        defaultSelectedKeys={[activeMenuItem]}
        selectedKeys={[activeMenuItem]}
        items={menuLeftFiltered}
        style={{ minWidth: 0, flex: 'auto' }}
      />
      {contextHolder}
    </div>
  );
}
