import { ConfigProvider, App } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import deDE from 'antd/lib/locale/de_DE';
import React from 'react';
import { useLang } from './store/common';

export type Lang = 'en' | 'de';

export const listLang = {
  en: enUS,
  de: deDE,
};

interface AntdConfigProviderProps {
  children: React.ReactNode
}

// export const COLOR_PRIMARY = 'rgb(51 130 235)';
// export const COLOR_PRIMARY_HOVER = 'rgb(7,64,141)';

export default function AntdConfigProvider({ children }: AntdConfigProviderProps): React.ReactNode {
  const { lang } = useLang();

  return (
    <ConfigProvider
      locale={listLang[lang]}
      theme={{
        token: {
          fontFamily: 'SF Pro Text',
          // colorPrimary: COLOR_PRIMARY,
          // colorLink: COLOR_PRIMARY,
          // colorLinkHover: COLOR_PRIMARY_HOVER,
          // colorLinkActive: COLOR_PRIMARY,
        },
      }}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
}
