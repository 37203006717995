import React, { useEffect } from 'react';
import Content from '../../Common/Content';
import { useLayoutContext } from '../../Layout/context';

import styles from './index.module.scss';

export default function Users(): React.ReactNode {
  const { setBreadcrumbs } = useLayoutContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        title: 'Users',
      },
    ]);
  }, []);

  return (
    <>
      <h2>Users page</h2>

      <Content>
        Users page
      </Content>
    </>
  );
}
