import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import ChangePasswordContent from '../../components/Pages/ChangePassword';
import Menu from '../../components/Layout/Menu';

const { displayName } = config;

export default function ChangePassword(): React.ReactNode | null {
  document.title = `${displayName}: Change password page`;

  return (
    <Layout LeftMenu={Menu} transparent>
      <ChangePasswordContent />
    </Layout>
  );
}
